/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
//import QS from 'qs';
//import { Toast } from 'vant';
//import store from '../store/index'

// 环境的切换 liy2GNU8
if (process.env.NODE_ENV === 'development') {
    //axios.defaults.baseURL = 'http://localhost:8889';
    axios.defaults.baseURL = 'https://www.eyeccy.com';
} else if (process.env.NODE_ENV === 'debug') {
    //axios.defaults.baseURL = 'http://8.140.146.205:8889';
    axios.defaults.baseURL = 'https://www.eyeccy.com';
} else if (process.env.NODE_ENV === 'production') {
    //axios.defaults.baseURL = 'http://8.140.146.205:8889';
    axios.defaults.baseURL = 'https://www.eyeccy.com';
}

// 请求超时时间
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = false
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        //QS.stringify(params)
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}


/*this.$axios.create({
       baseURL: "http://192.168.56.1:8183", // 基础url,如果是多环境配置这样写，也可以像下面一行的写死。
       // baseURL: process.env.VUE_APP_API,
       timeout: 6000, // 请求超时时间
      }).post('/user/login',params).then(res=>{
       console.log('登陆',res)
     })*/
