import { createRouter, createWebHashHistory } from 'vue-router'
import  constant from '../utils/Constant'
import commonUtils from'../utils/commUtils'

import Home from '../views/Home.vue'
import Login from '../views/login/login.vue'
import cardInfo from '../views/cardManager/cardInfo.vue' 

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children:[
      {
        path: '/index',
        name: 'index',
        label:'账户纵览',
        component: ()=>import('@/views/index/index.vue'),
      },
      {
        path: '/cardInfo',
        name: 'cardInfo',
        label:'卡信息',
        component: cardInfo
      },
      {
        path: '/flowPool',
        name: 'flowPool',
        label:'流量池',
        component: ()=>import('@/views/cardManager/flowPool.vue'),
      },
      {
        path: '/automaticStopRunMachine',
        name: 'automaticStopRunMachine',
        label:'批量停复机',
        component: ()=>import('@/views/cardManager/automaticStopRunMachine.vue'),
      },
      {
        path: '/buyNewCard',
        name: 'buyNewCard',
        label:'购新卡',
        component: ()=>import('@/views/buyNewCard/buyNewCard.vue'),
      },
      {
        path: '/buyNewCardRecord',
        name: 'buyNewCardRecord',
        label:'购卡记录',
        component: ()=>import('@/views/buyNewCard/buyNewCardRecord.vue'),
      },
      {
        path: '/takeRecharge',
        name: 'takeRecharge',
        label:'操作续费',
        component: ()=>import('@/views/rechargeManager/takeRecharge.vue'),
      },
      {
        path: '/autoRecharge',
        name: 'autoRecharge',
        label:'自动续费',
        component: ()=>import('@/views/rechargeManager/autoRecharge.vue'),
      },
      {
        path: '/rechargeRecord',
        name: 'rechargeRecord',
        label:'续费记录',
        component: ()=>import('@/views/rechargeManager/rechargeRecord.vue'),
      },
      {
        path: '/directFlowPool',
        name: 'directFlowPool',
        label:'直属流量池',
        component: ()=>import('@/views/digitalFlowPoolManager/directFlowPool.vue'),
      },
      {
        path: '/childFlowPool',
        name: 'childFlowPool',
        label:'子账户流量池',
        component: ()=>import('@/views/digitalFlowPoolManager/childFlowPool.vue'),
      },
      {
        path: '/subAccountList',
        name: 'subAccountList',
        label:'子账户列表',
        component: ()=>import('@/views/subAccountManager/subAccountList.vue'),
      },
      {
        path: '/subAccountCombo',
        name: 'subAccountCombo',
        label:'子账户套餐',
        component: ()=>import('@/views/subAccountManager/subAccountCombo.vue'),
      },
      {
        path: '/subAccountPersonal',
        name: 'subAccountPersonal',
        label:'子账户个性化',
        component: ()=>import('@/views/subAccountManager/subAccountPersonal.vue'),
      },
      {
        path: '/stopCardList',
        name: 'stopCardList',
        label:'停卡表',
        component: ()=>import('@/views/dataReport/stopCardList.vue'),
      },
      {
        path: '/useExceptionList',
        name: 'useExceptionList',
        label:'用量异常表',
        component: ()=>import('@/views/dataReport/useExceptionList.vue'),
      },
      {
        path: '/subAccountOrder',
        name: 'subAccountOrder',
        label:'子账户订单',
        component: ()=>import('@/views/dataReport/subAccountOrder.vue'),
      },
      {
        path: '/takeLogList',
        name: 'takeLogList',
        label:'操作日志',
        component: ()=>import('@/views/dataReport/takeLogList.vue'),
      },
      {
        path: '/inList',
        name: 'inList',
        label:'导入记录',
        component: ()=>import('@/views/inOrOut/inList.vue'),
      },
      {
        path: '/outList',
        name: 'outList',
        label:'导出记录',
        component: ()=>import('@/views/inOrOut/outList.vue'),
      },
      {
        path: '/updatePassword',
        name: 'updatePassword',
        label:'修改密码',
        component: ()=>import('@/views/sysSetting/updatePassword.vue'),
      },
      {
        path: '/updateEmail',
        name: 'updateEmail',
        label:'修改通知邮件',
        component: ()=>import('@/views/sysSetting/updateEmail.vue'),
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to,form,next)=>{
  if(to.path !== '/login'){ // 不在登录页面时
    let account=commonUtils.getSessionData(constant.account)
    if(commonUtils.noEmpty(account)){
      next()
    }else{
      window.localStorage.clear()
      router.push({path:'/login'})
      localStorage.setItem('isLogin',true)
    }
  }else{  // 登录页面
    next()
  }
})

export default router
