<template>
  <div class="home">
    <!--按钮-->
    <div class="common-horizon">
       <el-button type="warning" size="mini" style="margin-bottom: 5px;" @click="showDialog">发送短信</el-button>
    </div>
    <el-table :data="dataList"  border  height="calc(100% - 70px)" size="mini" @selection-change="handleSelectionChange" >
      <el-table-column type="selection" width="38" size="mini" />
      <el-table-column type="index" label="序号"  align="center" width="60px" />
      <el-table-column prop="iccid" label="ICCID" width="auto" align="center" min-width="190px" />
      <el-table-column prop="msisdn" label="MSISDN" width="auto" align="center" min-width="150px" />
      <el-table-column prop="imsi" label="IMSI" :formatter="setIMSI" width="auto" align="center" min-width="150px" />
      <el-table-column prop="isp" label="运营商" width="auto" align="center" />
      <el-table-column prop="orderDataplan" label="套餐" :formatter="order" width="100px"  align="center"/>
      <el-table-column prop="useMonth" label="本月用量" :formatter="monthUse"  align="center" width="90px" />
<!--      <el-table-column prop="orderDataplanTotal" label="套餐总量" :formatter="dataplanTatal" width="auto" align="center"/>-->
<!--      <el-table-column prop="orderDataplanUse" label="套餐已用" :formatter="dataplanUse" width="auto" align="center" />-->
<!--      <el-table-column prop="orderDataplanRemain" label="套餐剩余" :formatter="dataplanRemain" width="auto"  align="center"/>-->
      <el-table-column prop="supplierSimRecentlyStatus"  :formatter="cardState"  label="卡状态" width="70px" align="center" />
      <el-table-column prop="useMonthRenewTime" label="同步时间" width="auto" :formatter="syncTime" align="center" min-width="165px"/>
      <el-table-column prop="account" label="归属账号" width="auto"  align="center" min-width="100px"/>
      <el-table-column prop="custom" label="简称" width="auto"  align="center" min-width="125px"/>
      <el-table-column prop="orderNetworkType" label="网络类型" width="auto"  align="center" min-width="88px"/>
      <el-table-column prop="orderExpireDay" label="到期日期" width="auto"  align="center" min-width="165px"/>
      <el-table-column prop="orderActiveTime" label="激活日期" width="auto"  align="center" min-width="165px"/>
      <el-table-column prop="orderOpenTime" label="开卡日期" width="auto"  align="center" min-width="165px"/>
<!--      <el-table-column prop="orderTestDays" label="开卡测试期" width="auto"  align="center" min-width="125px"/>-->
<!--      <el-table-column prop="orderSilentDays" label="开卡沉默期" width="auto"  align="center" min-width="125px"/>-->
    </el-table>
    <div class="bottom">
      <el-pagination background layout="prev,sizes, pager, next" :total="toaltal" size="mini"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!--弹窗-->
    <div style="background: transparent;" class="dialog_one">

      <!--第一个弹窗-->
      <el-dialog v-model="dialogFormVisible" title="手动发送短信"    size="mini" modal="false" center="true"  >
        <el-form :model="form" :rules="rules1" ref="ruleFormRef" style="height:200px;" >
          <el-form-item label="MSISDN" prop="ICCID">
            <el-input v-model="form.ICCID"  :rows="8"
                      type="textarea" autocomplete="off"
                      placeholder="可直接复制ICCID或手动输入,一行一个" />
          </el-form-item>
          <el-form-item class="dialog-footer" size="mini">
            <el-button @click="dialogFormVisible = false" size="mini">取消</el-button>
            <el-button type="primary" @click="dialogFormVisibleNext('ruleFormRef')"  size="mini" >下一步</el-button>
          </el-form-item>
       </el-form>
      </el-dialog>

      <!--第二个弹窗-->
      <el-dialog v-model="dialogFormTwoVisible" title="模板短信" style="min-width: 500px;"   size="mini" modal="false" center="true" >
        <el-form :model="form2" :rules="rules2" ref="ruleFormRef2" label-position="Right" label-width="100px" >
          <el-form-item label="MSISDN"  prop="ICCID">
            <el-input v-model="form2.ICCID"  :rows="5"
                      type="textarea"
                      readonly="true"
                      placeholder="可直接复制ICCID或手动输入,一行一个" />
          </el-form-item>
          <el-form-item label="短信内容"  prop="content" style="margin-bottom: 20px;">
            <div>
              <div class="common-row">
                <span class="dialog_left_text">短信模板编号:</span>
                <el-select v-model="actionIds" style="width:180px;" @change="inputSelect" placeholder="请选择模板"
                           effect="light" clearable="true">
                  <el-option v-for="item in actionids"
                             :key="item.id"
                             :label="item.sms_number"
                             :value="item.sms_number">
                  </el-option>
                </el-select>
                <span class="dialog_money_pay" style="margin-left: 10px;" @click="addMoudle" >新增模板</span>
              </div>
              <div v-for="(item,index) in actionidsPrams" v-bind:key="index" class="top-10 common-row">
                <span class="dialog_left_text">短信模板参数{{index+1}}{{item.kk===undefined?'':''}}</span>
                <el-input style="width: 250px;" placeholder="请输入模板参数"/>
              </div>
              <div class="top-10 common-row">
                <span class="dialog_left_text">短信模板内容</span>
                <el-input v-model="form2.content"  :rows="3" style="width: 250px;"
                          type="textarea"   placeholder="短信模板内容"
                           />
              </div>
            </div>
          </el-form-item>

          <div class="dialog_money_pay margin-bottom-10"><span>应付金额:  </span><span>{{add_money}}元</span></div>
          <div class="dialog_money_pay margin-bottom-10"><span>账户余额:  </span><span>{{moneyRemain}}元</span></div>

          <el-form-item class="dialog-footer">
            <el-button @click="dialogFormTwoVisible = false" size="mini">取消</el-button>
            <el-button type="primary" @click="sureDialogTwo('ruleFormRef2')"  size="mini" >确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!--添加短信模板-->
      <el-dialog v-model="dialogSmsModlueVisible" title="新增短信模板"    size="mini" modal="false" center="true" >
        <el-form :model="form3" :rules="rules3" ref="ruleFormRef3" label-position="Right" label-width="150px" >
          <el-form-item label="短信模版内容"  prop="text" size="mini">
            <el-input v-model="form3.text"  :rows="3"
                      type="textarea"
                      size="mini"
                      placeholder="请输入短信模版内容" />
          </el-form-item>
          <!--注释-->
          <div style="color:#797979;size:14px;margin-left: 150px;">注：模板中可包含多个变量参数，变量参数用{数字}表示，且必须从1开始连续编号，如{1}、{2}、{3}。</div>
          <el-button class="variy" @click="insertVary">插入变量</el-button>

          <el-form-item label="模板短信应用声明"  prop="scene" style="margin-bottom: 20px;" size="mini">
            <el-input v-model="form3.scene"  :rows="3"
                      type="textarea"
                      placeholder="请输入短信模版场景声明" />
          </el-form-item>
          <!--注释-->
          <div style="color:#797979;size:14px;margin-left: 150px;margin-bottom: 10px;">注：为了构建和谐安全的网络环境，请认真填写关于申请模板短信后的应用场景，否则可能导致审核不通过。</div>

          <el-form-item class="dialog-footer" size="mini">
            <el-button @click="dialogSmsModlueVisible = false" size="mini">取消</el-button>
            <el-button type="primary" @click="sureDialogThree('ruleFormRef3')"  size="mini" >确定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

    </div>
  </div>
</template>

<script>
export default {
  name: "rechargeCardNumberManger",
  data:function () {
    let validatePass = (rule, value, callback) => {
      // callback(new Error('请输入密码'));   callback();  this.$refs.ruleForm.validateField('checkPass');
      //let patter=/[^\u4E00-\u9FA5]/g
      /*if(!patter.test(value)){
        callback(new Error('不允许输入汉字'));
      }*/
      let str=value.split('\n')
      if(str.length>1){
        for (let i = 0; i <str.length; i++) {
          let str2=str[i].split(' ')
          if(str2.length>1&&str2[1].trim().length>0){
            callback(new Error('清单行输入ICCID'));
            return
          }
          if((str[i].trim().length<11||str[i].trim().length>14)&&str[i].trim().length>0){
            callback(new Error('ICCID长度为18-22位'));
            return
          }
        }
      }else if(str.length===1){
        if((str[0].trim().length<11||str[0].trim().length>14)&&str[0].trim().length>0){
          callback(new Error('ICCID长度为18-22位'));
          return
        }
      }
      callback()
    };
    return {
      dataList:[],
      toaltal:0,
      pageNum:1,
      pageSize:10,
      iccid:'',
      msisdn:'',
      imsi:'',
      dialogFormVisible:false,
      lengthIccid:0,
      form:{
        ICCID:'',
      },
      /*第一个弹窗校验规则*/
      rules1:{
        ICCID: [
          { required: true, message: '当前有效ICCID为0个', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ]
      },
      dialogFormTwoVisible:false,
      form2:{
        ICCID:'',
        content:''
      },
      /*第二个弹窗校验规则*/
      rules2:{
        ICCID: [
          { required: true, message: '当前有效ICCID为0个', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        content:[
          { required: true, message: '请输入短信内容', trigger: 'blur' },
          {min:1,max:160, message: '最少输入1位短信，最多输入160位字符', trigger: 'blur' },
        ]
      },
      //短信应付金额
      add_money:0,
      //余额
      moneyRemain:0,
      //短信模板
      dialogSmsModlueVisible:false,
      form3:{
        text:'',
        scene:'' //应用场景
      },
      /*第3个弹窗校验规则*/
      rules3:{
        text: [
          { required: true, message: '请输入模板内容', trigger: 'blur' }
        ],
        scene: [
          { required: true, message: '请输入场景说明', trigger: 'blur' }
        ],
      },
      vary:[],
      //模板列表
      actionIds:{},
      actionids:[],
      selectItem:{},
      //模板参数
      actionidsPrams:[],
    }
    } ,
  components: {},
  mounted() {
    console.log('续费卡数 params',this.$route.params)
    let params=this.$route.params
    if(params.input!==undefined&&params.selectValue!==undefined){
      this.setParams(params)
    }
    //获取卡列表
    this.getCardInfoList();
    //获取余额
    this.getMoneyRemain();
    this.$emitter.on(this.$constant.getCardList, (params) => {
      console.log(params);
      if(params.selectValue!==''&&params.input!==''){
         this.setParams(params)
        this.getCardInfoList()
      }else{
        this.iccid=''
        this.msisdn=''
        this.imsi=''
        this.getCardInfoList()
      }
    })
  },
  methods:{
    //设置参数
    setParams:function(params){
      if(params.selectValue==='ICCID'){
        this.iccid=params.input
        this.msisdn=''
        this.imsi=''
      }else if(params.selectValue==='MSISDN'){
        this.iccid=''
        this.msisdn=params.input
        this.imsi=''
      }else if(params.selectValue==='IMSI'){
        this.iccid=''
        this.msisdn=''
        this.imsi=params.input
      }
    },
    //列表选择
    handleSelectionChange:function(selects){
      let append=''
      let k=0;
      if(selects.length>0){
        for (let i = 0; i <selects.length ; i++) {
          console.log('msisdn',selects[i].msisdn)
          if(!this.$commonUtils.isEmpty(selects[i].msisdn)){
            append+=selects[i].msisdn+'\n'
            k++;
          }
        }
      }
      this.lengthIccid=k
      this.add_money=this.lengthIccid*0.1
      this.form2.ICCID=append;
    },
    //展示弹窗
    showDialog:function(){
      console.log('展示弹窗')
      if(this.lengthIccid>0){
        this.dialogFormTwoVisible=true
        //获取短信模板
        this.getActionids();
      }else{
        this.dialogFormVisible=true
      }
    },
    //展示第二个弹窗 dialogFormVisible2
    dialogFormVisibleNext:function(formName){
      if(formName!==undefined){
        let that=this
        this.$refs[formName].validate((valid, fields) => {
          if (valid) {
             that.form2.ICCID=that.form.ICCID
            let str=that.form2.ICCID.split('\n')
            let k=0;
            for (let i = 0; i <str.length ; i++) {
              if(str[i].trim().length>0){
                k+=1;
              }
            }
            that.lengthIccid=k
            that.add_money=that.lengthIccid*0.1
            that.dialogFormVisible=false
            that.dialogFormTwoVisible=true
            //获取短信模板
            that.getActionids();
          } else {
            console.log('error submit!', fields)
          }
        })
      }
    },
    //获取msisdns
    getMsisdns:function(){
     let data= this.form2.ICCID
      console.log('getMsisdns',data)
      let append=""
      if(!this.$commonUtils.isEmpty(data)){
         data=data.split("\n")
        if(data.length>0){
          for (let i = 0; i <data.length ; i++) {
            if(!this.$commonUtils.isEmpty(data[i])){
              append+=data[i]+'_'
            }
          }
          return append.substring(0,append.length-1)
        }
      }
      return append;
    },
    //第二个弹窗点击事件
    sureDialogTwo:function(formName){
      if(formName!==undefined){
        let that=this
        this.$refs[formName].validate((valid, fields) => {
          if (valid) {
            //todo 进行网络请求
            if(this.actionids.length===0){
              that.$message({
                message: "请和客户经理确认短信功能是否开通",
                type: "error",
              })
            }else if((this.lengthIccid*0.1)>=parseFloat(that.moneyRemain)){
              that.$message({
                message: "余额不足,请充值!",
                type: "error",
              })
            }else{
              let that=this
              let msisdns= this.getMsisdns()
              let account=this.$commonUtils.getSessionData(this.$constant.account)
              let params={
                account:account,
                text:this.selectItem.text,
                price:'0.1',
                total:this.lengthIccid,
                actionid:this.selectItem.sms_number,
                msisdns:msisdns
              }
              //console.log('sureDialogTwo->params: ',params)
              that.$post(that.$urlUtils.sendSmsNoParam,that.$QS.stringify(params)).then(res => {
                console.log('sureDialogThree',res)
                if(res.status===200){
                  that.$message.success("发送短信成功!")
                }else{
                  that.$message.error("发送短信失败!")
                }
              })
            }
          } else {
            console.log('error submit!', fields)
          }
        })
      }
    },
    //展示第三个 弹窗
    addMoudle:function(){
      this.dialogSmsModlueVisible=true
    },
    //第三个弹窗点击事件
    sureDialogThree:function(formName){
      if(formName!==undefined){
        this.$refs[formName].validate((valid, fields) => {
          if (valid) {
            let account=this.$commonUtils.getSessionData(this.$constant.account)
            let params={
              account:account,
              text:this.form3.text,
              scene:this.form3.scene,
              status:"N"
            }
            let that=this
            this.$post(this.$urlUtils.addActionids,this.$QS.stringify(params)).then(res => {
              console.log('sureDialogThree',res)
              if(res.status===200){
                that.$message.success("新增模板成功，请等待审核!")
                that.dialogSmsModlueVisible=false
              }
            })
          } else {
            console.log('sureDialogThree', fields)
          }
        })
      }
    },
    //插入变量
    insertVary:function(){
      let patter=/[{][0-9][}]/g
      let dadtas=this.form3.text.match(patter)
      if(dadtas.length>0){
        let max=0
        for (let i = 0; i <dadtas.length ; i++) {
           let str=dadtas[i].replace("{",'').replace("}","")
           if(parseInt(str)>max){
             max=parseInt(str);
           }
        }
        this.form3.text=this.form3.text+'{'+(max+1)+'}'
      }else{
        this.form3.text=this.form3.text+'{'+(1)+'}'
      }
    },
    //短信模板选择
    inputSelect:function(item){
      console.log('短信模板选择',item)
      for (let i = 0; i < this.actionids.length; i++) {
        if(this.actionids[i].sms_number===item){
          this.selectItem=this.actionids[i]
          this.form2.content=this.selectItem.text
          break
        }
      }
    },
    //获取余额
    getMoneyRemain:function(){
      let account=this.$commonUtils.getSessionData(this.$constant.account)
      let params={
        account:account,
      }
      let that=this
      this.$getRequest(this.$urlUtils.amountGetMoneyRemain,params).then(res => {
        console.log('getMoneyRemain',res)
        that.moneyRemain=res.data==null?'0.00':res.data;
      })
    },
    //获取列表
    getCardInfoList:function () {
      let account=this.$commonUtils.getSessionData(this.$constant.account)
      let params={
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        account:account,
        iccid:this.iccid,
        msisdn:this.msisdn,
        imsi:this.imsi
      }
      let that=this
       this.$post('/card/getListCardInfo',this.$QS.stringify(params)).then(res => {
         that.dataList=res.data.list
         that.toaltal=res.data.total
        console.log('getListCardInfo',that.toaltal,res.data)
       })
    },
    //分页回调
    handleSizeChange:function(number){
      console.log('number1',number)
      this.pageSize=number
      this.getCardInfoList()
    },
    //分页回调
    handleCurrentChange:function(number){
      console.log('number2',number)
      this.pageNum=number
      this.getCardInfoList()
    },
    //获取短信模板
    getActionids:function(){
      let account=this.$commonUtils.getSessionData(this.$constant.account)
      let params={
        account:account
      }
      this.$getRequest(this.$urlUtils.getActionids,params).then(res => {
        console.log('getActionids',res.data)
        this.actionids=res.data
        if(this.actionids.length>0){
          let item=this.actionids[0].sms_number
          this.inputSelect(item)
        }
      })
    },
    /** imsi */
    setIMSI:function(row){
      if(row.imsi!==null&&row.imsi!=='null'){
        return row.imsi;
      }else{
        return "-";
      }
    },
    /** 套餐 */
    order:function(row){
      if(row.orderDataplan!==null&&row.orderDataplan!=='null'&&row.orderDataplan!==0){
        return row.orderDataplan;
      }else{
        return "-";
      }
    },
    /** 本月用量 */
    monthUse:function(row){
      //useMonth/order_unit
      //console.log('row.useMonth',row.useMonth,row.orderUnit)
      if(row.useMonth!==null&&row.useMonth!=='null'&&row.useMonth!==0){
        if(row.orderUnit!==null&&row.orderUnit!==''&&row.orderUnit!=='null'){
          if(row.orderUnit==='NB'){
            return row.useMonth.toFixed(3);
          }else if(row.orderUnit.indexOf('G')!==-1){
            let number=parseFloat(row.useMonth)/1024
            return number.toFixed(3)+'G';
          }else{
            return row.useMonth.toFixed(3)+row.orderUnit;
          }
        }
        return row.useMonth.toFixed(3);
      }else{
        return "-";
      }
    },
    /** 同步时间 */
    syncTime:function(row){
      if(row.useMonthRenewTime!==null&&row.useMonthRenewTime!=='null'){
        return row.useMonthRenewTime;
      }else{
        return "-";
      }
    },
    /** 套餐总量 */
    dataplanTatal:function(row){
      if(row.orderDataplanTotal!==null&&row.orderDataplanTotal!=='null'){
        return row.orderDataplanTotal;
      }else{
        return "-";
      }
    },
    /** 套餐已用 */
    dataplanUse:function(row){
      if(row.orderDataplanUse!==null&&row.orderDataplanUse!=='null'){
        return row.orderDataplanUse;
      }else{
        return "-";
      }
    },
    /** 套餐剩余 */
    dataplanRemain:function(row){
      if(row.orderDataplanRemain!==null&&row.orderDataplanRemain!=='null'){
        return row.orderDataplanRemain;
      }else{
        return "-";
      }
    },
    /** 状态 格式化*/
    cardState:function(row) {
      //'1': '可激活', '2': '已激活', '4': '已停卡', '6': '可测试', '7': '库存', '8': '已销卡'
      if(row.supplierSimRecentlyStatus!==null&&row.supplierSimRecentlyStatus!=='null'){
          return row.supplierSimRecentlyStatus;
      }else{
         return "-";
      }
    }
  }
}
</script>

<style scoped>
  .home{
    padding: 10px;
    height: calc(100% - 20px);
  }
  .bottom{
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
  .common-horizon{
    display:flex;
    flex-direction: row;
    align-items: center;
  }
  .common-row{
    display:flex;
    flex-direction: row;
  }
  /*005BAC*/
  .dialog_one>>>.el-dialog{
    --el-dialog-box-shadow:0 0 0 rgba(0, 0, 0, 0);
    background: #ACE1F5;
  }
  .dialog_one>>>.el-dialog__title{
    color: #005BAC;
  }
  .dialog_one>>>.el-form-item__label{
    color: #005BAC;
  }
  .dialog-footer{
    float: right;
    margin-top: -10px;
  }
  .vertical{
    display: flex;
    flex-direction: column;
  }
  .dialog_money_pay{
    size: 28px;
    margin-left: 100px;
    color: #005BAC;
    font-weight: bold;
  }
  .dialog_left_text{
    width: 100px;
    size: 28px;
    color: #005BAC;
  }
  .margin-bottom-10{
    margin-bottom: 10px;
  }
  .top-10{
    margin-top: 5px;
  }
  .variy{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 150px;
    width: 70px;
    color: #C3C3C3;
    background: white;
    border-radius: 5px;
  }
</style>
