<template>
  <el-container style="height: 100%">
    <!-- 左侧   -->
    <el-aside width="200px" style="background:#036EB8;height: 100%;">
      <div style="width: 100%;" class="common-center"><img src="../assets/img/icona-logo.png" class="left-logo"></div>
      <div style="margin-top: 15px;">
        <!--   031F32    #ffd04b -->
        <el-menu
            :default-active="this.$route.name"
            class="el-menu-vertical"
            active-text-color="#ffd04b"
            text-color="white"
            background-color="transparent"
            router="true"
            :unique-opened="true"
            @open="handleOpen"
            @close="handleClose">
          <template v-for="(item,index) in datasMenu" :key="item.name" :value="index">
            <template v-if="item.children.length===0" style="position: relative;">
              <el-icon color="#409EFC" :size="20" class="index-icon"><img class="svg-left-icon" :src="backIcon(item.icon)"/></el-icon>
              <el-menu-item :key="item.name" :value="index" :index="item.name" style="color: white;"><span style="padding-left: 30px;">{{ item.label }}</span>
              </el-menu-item>
            </template>
            <template v-else>
              <el-sub-menu :index="item.name">
                <template #title>
                  <el-icon color="#409EFC" :size="20"><img class="svg-left-icon" :src="backIcon(item.icon)"/></el-icon>
                  <span>{{ item.label }}</span>
                </template>
                <el-menu-item v-for="(chidren,index2) in item.children" :key="chidren.name" :value="index2"
                              :index="chidren.name">{{ chidren.label }}
                </el-menu-item>
              </el-sub-menu>
            </template>

          </template>

        </el-menu>
      </div>
    </el-aside>
    <!-- 右侧   -->
    <el-container style="background: #f5f5f5;">
      <!-- 头部   -->
      <el-header class="headers">
        <!-- 搜索   -->
        <div class="header1 common-center-between">
          <div>
            <el-select v-model="selectValue" class="top-select" placeholder="请选择" size="small" effect="light" clearable="true">
              <el-option
                  v-for="item in selects"
                  :key="item.key"
                  :label="item.value"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="input" placeholder="请输入ICCID／MSISDN/IMSI" class="serarch-input" size="small" clearable="true"/>
            <!-- 查询按钮-->
            <el-button size="small" style="margin-left: 20px;" @click="search">查询</el-button>
          </div>
          <img src="../assets/img/icon-logo-small.png" style="width: 25px;height: 25px;margin-right: 10px;"/>
        </div>
        <!-- 卡总数   -->
        <div class="header2 top-num-797979 common-center-between">
          <div>
            <span>总卡数</span><span class="top-num-3296ED">{{cardNumTotal}}张</span><span> , 本月待续费卡数</span><span class="top-num-3296ED">{{cardXuFeiCarTotal}}张</span>
<!--            <span>,账户余额</span><span class="top-num-3296ED">2850.23</span><span>元</span>-->
          </div>
          <div class="common-center-vertical" @click="backLogin">
            <img src="../assets/img/icon-back.png" class="icon-back">
            <span class="top-num-797979" style="margin-right: 10px;margin-left: 2px;">退出</span>
          </div>
        </div>
      </el-header>
      <!-- 主要内容   -->
      <el-main class="el-main-home">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import datasMenu from '../assets/js/menuData.js'
import commonDatas from '../utils/commonData'

export default {
  name: 'Home',
  data: function () {
    return {
      datasMenu: datasMenu,
      isShowIndex: false,
      options: [],
      selectValue: 'ICCID',
      input: '',
      currentIndex:'',
      selects:commonDatas.headerSelects,
      cardNumTotal:0,//总卡数
      cardXuFeiCarTotal:0,//续费卡数
    }
  },
  components: {},
  mounted() {
    if(this.$route.name==='Home'){
      this.$router.replace({
        //name: 'Home'
        name: 'cardInfo'
      });
    }else{
      this.currentIndex=this.$route.name
    }
    //获取总卡数
    this.getTotalNum(this)
  },
  methods: {
    /** 打开  */
    handleOpen: function (key, keyPath) {
      console.log('handleOpen', key, keyPath)
      if (key === 'index') {
        this.isShowIndex = true
        this.$router.push({
          name: 'index'
        });
      }
    },
    /** 关闭  */
    handleClose: function (key, keyPath) {
      console.log('handleClose', key, keyPath)
    },
    backIcon: function (icon) {
      return require('@/assets/img/' + icon);
    },
    /** 退出登陆 */
    backLogin:function () {
      this.$message({
        message: "退出登陆",
        type: "info",
      })
      this.$commonUtils.setSessionData(this.$constant.account,'')
      this.$commonUtils.setSessionData(this.$constant.loginData,'')
      this.$router.replace({
        name: 'login'
      })
    },
    //获取总卡数
    getTotalNum(that) {
      let account=that.$commonUtils.getSessionData(that.$constant.account)
      let params={
        account:account
      }

      that.$getRequest('/card/getTatalNum',params).then(res => {
        that.cardNumTotal=res.data.cardNumTotal
        that.cardXuFeiCarTotal=res.data.cardXuFeiCarTotal
      })
    },
    //查询
    search:function () {
      let routeName=this.$route.name
      console.log('this.$route.name',routeName)
      if(routeName!=='cardInfo'){
        this.$router.push({name:"cardInfo",params:{ input: this.input,selectValue:this.selectValue }});
      }else{
        this.$emitter.emit(this.$constant.getCardList, { input: this.input,selectValue:this.selectValue });
      }
    }
  },

}
</script>

<style lang="scss" scoped>
.root {
  width: 12px;
}

.common-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.common-center-vertical {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.common-center-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left-logo {
  width: 160px;
  height: 50px;
  margin-top: 15px;
}

.el-menu-vertical {
  margin-top: 15px;
}

.headers {
  background: gold;
  height: 80px;
  width: calc(100% - 10px);
  min-width: 794px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
}

.headers > > > .el-header {
  --el-header-padding: 0 0;
  padding: 0;
}

.header1 {
  background: #555555;
  height: 40px;
}

.header2 {
  background: white;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
}

.el-main-home {
  background: white;
  height: calc(100% - 80px);
  width: calc(100% - 10px);
  min-width: 794px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 0px;
}

.svg-left-icon {
  width: 15px;
  height: 15px;
}

.top-select {
  margin-left: 10px;
  width: 140px;
  background: transparent;
}

.serarch-input {
  width: 300px;
  margin-left: 10px;
}

.top-num-797979 {
  color: #797979;
}

.top-num-3296ED {
  color: #3296ED;
}

.icon-back {
  width: 15px;
  height: 10px;
}

.sub-menu1 {

}

.index-icon{
  position: absolute;
  top: 20px;
  left: 25px;
  z-index: 100;
}


</style>
