<template>
  <el-main class="background">

    <el-container class="container">
      <div style="margin: auto;">
        <!--   logo     -->
        <div><img src="../../assets/img/icona-logo.png" class="icon-logo" /></div>
        <!--   账号密码输入     -->
        <div class="content">
          <li class="title">Doeye管理平台</li>
          <!-- 账号 -->
          <div class="item-root">
            <li class="left-image"><img src="../../assets/img/icona-password.png" class="icon-left"/></li>
            <el-input type="text" class="right-input" placeholder="请输入账号" v-model="account" />
          </div>
          <!-- 密码 -->
          <div class="item-root" style="margin-top: 10px;">
            <li class="left-image"><img src="../../assets/img/icona-account.png" class="icon-left"/></li>
            <el-input  type="password" class="right-input" placeholder="请输入密码" v-model="passwrod" />
          </div>
          <!-- 忘记密码 -->
          <div style="margin-top: 10px;" class="common-horizon-between">
            <el-checkbox class="checkbox" @change="remeberPassword" v-model="isCheckPassword">记住密码</el-checkbox>
            <el-button @click="startLogin" size="small" color="#2A6EB2" style="margin-right: 20px;margin-top: 40px;color: white;">登录
            </el-button>
          </div>

        </div>
        <!--   万物互联，智者载物     -->
        <div class="bottom-txt">万&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;物&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;互&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;者&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;载
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;物</div>
        <!--备案号-->
        <div class="bottom-txt-beihao"><a href="https://beian.miit.gov.cn/">沪ICP备20004957号-2</a></div>
      </div>
    </el-container>

    <div class="bg-login">
       <img src="../../assets/img/icona-login-bg.png">
    </div>

  </el-main>
</template>

<script>
/* 导入 */
//import commonUtils from '../../utils/commUtils'

export default {
  name: 'Login',
  components: {},
  data: function () {
    return {
      isCheckPassword:false,
      account:'',
      passwrod:''
    }
  },
  mounted() {
    let isCheckPassword=localStorage.getItem(this.$constant.isCheckPassword);
    if(isCheckPassword ==='true'){
      this.account=localStorage.getItem(this.$constant.account);
      this.passwrod=localStorage.getItem(this.$constant.password);
      this.isCheckPassword=true
    }
  },
  methods:{
    //开启登陆
    startLogin:function (){
      if(this.$commonUtils.isEmpty(this.account)){
        this.$message({
          message: "请填写账号",
          type: "warning",
        })
        return
      }
      if(this.$commonUtils.isEmpty(this.passwrod)){
        this.$message({
          message: "请填写密码",
          type: "warning",
        })
        return
      }
      var params={
        account:this.account,
        passwrod:this.passwrod,
      }

      let that=this
      this.$post('/user/login',params).then(res => {
        parseData(that,res,that.passwrod)
      })
    },
    //记住密码
    remeberPassword:function (data) {
      console.log('remeber',data,this.isCheckPassword)
    }
  }
}

//解析登陆数据
function parseData(that,data,passwrod) {
  let account=data.account
  if(that.$commonUtils.noEmpty(account)){
    that.$message({
       message: "登陆成功",
       type: "success",
     })
    that.$commonUtils.setSessionData(that.$constant.loginData,that.$QS.stringify(data))
    that.$commonUtils.setSessionData(that.$constant.account,account)
    if(that.isCheckPassword===true||that.isCheckPassword==='true'){
      localStorage.setItem(that.$constant.account,that.account)
     localStorage.setItem(that.$constant.password,passwrod)
     localStorage.setItem(that.$constant.isCheckPassword,"true")
    }else{
     localStorage.setItem(that.$constant.account,'')
     localStorage.setItem(that.$constant.password,'')
     localStorage.setItem(that.$constant.isCheckPassword,"false")
    }
    that.$router.replace({
      name: 'Home'
    });
  }else{
    that.$message({
      message: "登陆失败",
      type: "error",
    })
  }
}

</script>

<style lang="scss">
@import "../../assets/css/global.css";

.background {
  background: #2E6DB0;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  text-align: center;
  padding-top: 10%;
  z-index: 2;
  position: relative;
}

.content {
  background: #F1F1F1;
  border-radius: 8px;
  width: 300px;
  height: auto;
  margin: auto;
  padding: 10px 10px 20px;
}

.title {
  font-size: 16px;
  color: #316EAF;
  list-style: none;
}

.item-root {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.left-image {
  width: 20%;
  height: 35px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #316EAF;
  list-style: none;
  margin-left: 20px;
  margin-top: 3px;
}

.right-input {
  width: 80%;
  height: 35px;
  margin-right: 20px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: #F1F1F1;
}

input::-webkit-input-placeholder {
  color: #E4E4E4;
}

.checkbox {
  text-align: left;
  color: #CBCBCB;
  font-size: 14px;
  margin-left: 20px;
}

.icon-left {
  width: 15px;
  height: 15px;
  margin-top: 10px;
}

.icon-logo{
  width: 150px;
  height: 50px;
  margin-bottom: 20px;
}

.bottom-txt{
  font-size: 24px;
  margin: 50px auto auto;
  color: #205FA4;
}

.bottom-txt-beihao{
  font-size: 13px;
  margin: 50px auto auto;
}

.bg-login{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.bg-login img{
  width: 400px;
  height: 400px;
}

</style>
