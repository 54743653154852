<template>
    <router-view/>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {

  }
}
</script>

<style lang="scss" >
@import "./assets/css/global.css";

html,body,#app{
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 14px;
  background: white;
  height: 100%;
}


</style>
