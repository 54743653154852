module.exports={
    baseUrl:getBase(),
    //获取卡列表
    cardGetListCardInfo:'/card/getListCardInfo',
    //获取流量池列表
    cardGetListCardPools:'/card/getListCardPools',
    //图片上传
    uploadUploadImage:'/upload/uploadImage',
    //缴费充值  1->充值 2->短信
    amountAddMoneyRecord:'/amount/addMoneyRecord',
    //获取 余额
    amountGetMoneyRemain:'/amount/getMoneyRemain',
    //短信模板添加 sms
    addActionids:'/sms/addActionids',
    //获取短信模板列表
    getActionids:'/sms/getActionids',
    //发送无参数模板短信
    sendSmsNoParam:'/sms/sendSmsNoParam',
    //修改密码
    updatePassword:'/user/updatePassword',
}

function getBase() {
    if (process.env.NODE_ENV === 'development') {
       // return  'http://localhost:8889' //本地环境
       //return 'http://8.140.146.205:8889'  //直接调线上环境
       return "https://www.eyeccy.com";
    } else if (process.env.NODE_ENV === 'debug') {
       // return 'http://localhost:8889'   //本地环境
       //return 'http://8.140.146.205:8889'  //直接调线上环境
       return "https://www.eyeccy.com";
    } else if (process.env.NODE_ENV === 'production') {
        // return 'http://39.107.76.182:8889'
        //return 'http://8.140.146.205:8889'
        return "https://www.eyeccy.com";
    }
}
