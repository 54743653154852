module.exports={
    isEmpty:isEmpty,
    noEmpty:noEmpty,
    setSessionData:setSessionData,
    getSessionData:getSessionData,
    setLocalData:setLocalData,
    getLocalData:getLocalData,
}

/** 判断是否为空 */
function isEmpty(str) {
  if(str===null||str==='null'||str===''||str===undefined||str.length===0){
      return true
  }
  return false
}

/** 判断是否为空 */
function noEmpty(str) {
    if(str!==null&&str!=='null'&&str!==''&&str!==undefined&&str.length!==0){
        return true
    }
    return false
}

/** 保存 零时缓存 */
function setSessionData(key,str) {
  sessionStorage.setItem(key,str)
}

/** 获取 零时缓存 */
function getSessionData(key) {
   return  sessionStorage.getItem(key)
}

/** 保存 磁盘缓存 */
function setLocalData(key,str) {
    localStorage.setItem(key,str)
}

/** 获取 磁盘缓存 */
function getLocalData(key,str) {
    localStorage.setItem(key,str)
}


