import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'


import axios from 'axios'
import QS from 'qs';
import { Notify } from 'vant';

//工具类
import commonUtils from'./utils/commUtils'
import  {post,get} from './utils/loginUtil'
import  constant from './utils/Constant'
import  emitter from './utils/eventBus'
import  urlUtils from './utils/UrlUtils'

const app=createApp(App).use(store).use(router).use(ElementPlus).use(commonUtils)
app.mount('#app')
app.config.globalProperties.$commonUtils=commonUtils
app.config.globalProperties.$message=ElMessage
app.config.globalProperties.$axios=axios
app.config.globalProperties.$QS=QS
app.config.globalProperties.$Notify=Notify
app.config.globalProperties.$post=post
app.config.globalProperties.$getRequest=get
app.config.globalProperties.$constant=constant
app.config.globalProperties.$emitter=emitter
app.config.globalProperties.$urlUtils=urlUtils

