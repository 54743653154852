module.exports =[
    // {
    //     name:'index',
    //     label:'账户总览',
    //     path:'/index/index',
    //     icon:'icon_home.png',
    //     children:[]
    // },
    {
        name:'cardManager',
        label:'卡管理',
        path:'',
        icon:'icon_left_card.png',
        children:[
            {
                path: '/cardManager/cardInfo',
                name: 'cardInfo',
                label:'卡信息'
            },
            // {
            //     path: '/cardManager/flowPool',
            //     name: 'flowPool',
            //     label:'流量池'
            // },
            {
                path: '/cardManager/automaticStopRunMachine',
                name: 'automaticStopRunMachine',
                label:'批量停复机'
            },
        ]
    },
    // {
    //     name:'buyNewCard',
    //     label:'购买新卡',
    //     path:'',
    //     icon:'icon_left_card.png',
    //     children:[
    //         {
    //             path: '/buyNewCard/buyNewCard',
    //             name: 'buyNewCard',
    //             label:'购新卡'
    //         },
    //         {
    //             path: '/buyNewCard/buyNewCardRecord',
    //             name: 'buyNewCardRecord',
    //             label:'购卡记录'
    //         },
    //     ]
    // },
    {
        name:'digitalFlowPoolManager',
        label:'流量池管理',
        path:'',
        icon:'icon-left-pool.png',
        children:[
            {
                path: '/digitalFlowPoolManager/directFlowPool',
                name: 'directFlowPool',
                label:'直属流量池'
            },
            // {
            //     path: '/digitalFlowPoolManager/childFlowPool',
            //     name: 'childFlowPool',
            //     label:'子账户流量池'
            // }
        ]
    },
    {
        name:'rechargeManager',
        label:'续费管理',
        path:'',
        icon:'icon-left-money.png',
        children:[
            {
                path: '/rechargeManager/takeRecharge',
                name: 'takeRecharge',
                label:'充值'
            },
            // {
            //     path: '/rechargeManager/autoRecharge',
            //     name: 'autoRecharge',
            //     label:'自动续费'
            // },
            // {
            //     path: '/rechargeManager/rechargeRecord',
            //     name: 'rechargeRecord',
            //     label:'续费记录'
            // }
        ]
    },
    //,
    // {
    //     name:'subAccountManager',
    //     label:'子账户管理',
    //     path:'',
    //     icon:'icon-left-child-account.png',
    //     children:[
    //         {
    //             path: '/subAccountManager/subAccountList',
    //             name: 'subAccountList',
    //             label:'子账户列表'
    //         },
    //         {
    //             path: '/subAccountManager/subAccountCombo',
    //             name: 'subAccountCombo',
    //             label:'子账户套餐'
    //         },
    //         {
    //             path: '/subAccountManager/subAccountPersonal',
    //             name: 'subAccountPersonal',
    //             label:'子账户个性化'
    //         }
    //     ]
    // },
    // {
    //     name:'dataReport',
    //     label:'数据报表',
    //     path:'',
    //     icon:'icon-left-report.png',
    //     children:[
    //         {
    //             path: '/dataReport/stopCardList',
    //             name: 'stopCardList',
    //             label:'停卡表'
    //         },
    //         {
    //             path: '/dataReport/useExceptionList',
    //             name: 'useExceptionList',
    //             label:'用量异常表'
    //         },
    //         {
    //             path: '/dataReport/subAccountOrder',
    //             name: 'subAccountOrder',
    //             label:'子账户订单'
    //         },
    //         {
    //             path: '/dataReport/takeLogList',
    //             name: 'takeLogList',
    //             label:'操作日志'
    //         }
    //     ]
    // },
    // {
    //     name:'inOrOut',
    //     label:'导入导出',
    //     path:'',
    //     icon:'icon-left-in-out.png',
    //     children:[
    //         {
    //             path: '/inOrOut/inList',
    //             name: 'inList',
    //             label:'导入记录'
    //         },
    //         {
    //             path: '/inOrOut/outList',
    //             name: 'outList',
    //             label:'导出记录'
    //         }
    //     ]
    // },
    {
        name:'sysSetting',
        label:'系统设置',
        path:'',
        icon:'icon-left-setting.png',
        children:[
            {
                path: '/sysSetting/updatePassword',
                name: 'updatePassword',
                label:'修改密码'
            },
           /* {
                path: '/sysSetting/updateEmail',
                name: 'updateEmail',
                label:'修改通知邮件'
            }*/
        ]
    },
]
